import { constants } from '@/styles';
import { type StyleFunction } from '@/styles/models';

export const dropContainerSize = {
  short: { height: 28, width: 210 },
  regular: { height: 125, width: 210 },
  large: { height: 500, width: 850 },
  higher: { height: 145, width: 210 },
};

export const dropContainerStyles: StyleFunction = ({ theme }) => ({
  marginRight: constants.offset.xlarge,
  borderRadius: constants.borderRadius.small,
  position: 'relative',

  '.drop-zone': {
    backgroundColor: theme.backgroundTenth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...dropContainerSize.regular,

    '&:focus': {
      outline: 'none',
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.backgroundTenthHover,
    },

    '&--dragged': {
      backgroundColor: theme.backgroundTenthHover,
    },

    '&--short': {
      ...dropContainerSize.short,
    },

    '&--disabled': {
      cursor: 'not-allowed !important',

      '&:hover': {
        backgroundColor: theme.backgroundFifth,
      },
    },

    '&--large': {
      ...dropContainerSize.large,
    },

    '&--higher': {
      ...dropContainerSize.higher,
    },

    '.image-preview': {
      maxWidth: dropContainerSize.regular.width,
      maxHeight: dropContainerSize.regular.height,

      '&--large': {
        maxWidth: dropContainerSize.large.width,
        maxHeight: dropContainerSize.large.height,
      },

      '&--higher': {
        maxWidth: dropContainerSize.higher.width,
        maxHeight: dropContainerSize.higher.height,
      },
    },

    '.file-name': {
      margin: `0 ${constants.offset.small}px`,
      maxWidth: dropContainerSize.regular.width - 2 * constants.offset.small,
      textAlign: 'center',
      maxHeight: '2.3em',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.textPrimary,

      '&--large': {
        margin: `0 ${constants.offset.large}px`,
        maxWidth: dropContainerSize.large.width - 2 * constants.offset.large,
      },
    },
  },

  '.download-button': {
    position: 'absolute',
    bottom: constants.offset.small,
    left: constants.offset.small,
    width: 32,
    height: 32,
    backgroundColor: theme.backgroundPrimary,
    borderRadius: constants.borderRadius.xlarge,

    '& > *': {
      paddingLeft: constants.offset.xsmall,
    },
  },
});
