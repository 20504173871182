import { PAGE_ID } from '@/common/constants';
import { managementRoutePaths, managementRoutes, reportingPaths, ReportingRoutes } from '@/core/navigation';
import {
  authenticatedRoutePaths,
  authenticatedRoutes,
  accountsRoutes,
  accountsRoutePaths,
  dashboardRoutes,
  dashboardRoutePaths,
  contentRoutePaths,
  contentRoutes,
} from '../../navigation';
import { NavGroup } from './models';

export const NAV_LINK_ID_PREFIX = 'NAV_LINK_';

export const links: NavGroup[] = [
  {
    label: 'topBar.group.dashboard',
    url: authenticatedRoutePaths[authenticatedRoutes.DASHBOARD],
    elements: [
      {
        label: 'topBar.link.shortcuts',
        url: dashboardRoutePaths[dashboardRoutes.SHORTCUTS],
      },
    ],
  },
  {
    label: 'topBar.group.accounts',
    url: authenticatedRoutePaths[authenticatedRoutes.ACCOUNTS],
    elements: [
      {
        label: 'topBar.link.users',
        url: accountsRoutePaths[accountsRoutes.USER_LIST],
        permissions: [{ subject: 'USERS', scope: 'SEARCH' }],
        urlMatchPattern: [accountsRoutePaths[accountsRoutes.USER_LIST], accountsRoutePaths[accountsRoutes.USER_DETAILS]],
      },
      {
        label: 'topBar.link.organisations',
        url: accountsRoutePaths[accountsRoutes.ORGANISATION_LIST],
        permissions: [{ subject: 'ORGANISATIONS', scope: 'SEARCH', realm: 'ANY' }],
        urlMatchPattern: [accountsRoutePaths[accountsRoutes.ORGANISATION_LIST], accountsRoutePaths[accountsRoutes.ORGANISATION_DETAILS]],
        urlExcludedFromMatch: [accountsRoutePaths[accountsRoutes.MY_ORGANISATION]],
      },
      {
        label: 'topBar.link.myOrganisation',
        url: accountsRoutePaths[accountsRoutes.MY_ORGANISATION],
        urlMatchPattern: [accountsRoutePaths[accountsRoutes.MY_ORGANISATION]],
      },
      {
        label: 'topBar.link.invitations',
        permissions: [{ subject: 'INVITATION', scope: 'SEARCH' }],
        url: accountsRoutePaths[accountsRoutes.INVITATION_LIST],
        urlMatchPattern: [accountsRoutePaths[accountsRoutes.INVITATION_LIST]],
      },
    ],
  },
  {
    label: 'topBar.group.content',
    url: authenticatedRoutePaths[authenticatedRoutes.CONTENT],
    elements: [
      {
        label: 'topBar.link.routes',
        permissions: [{ subject: 'ROUTES', scope: 'SEARCH' }],
        url: contentRoutePaths[contentRoutes.ROUTES_LIST],
        navLinkId: NAV_LINK_ID_PREFIX + PAGE_ID.ROUTES_LIST,
      },
      {
        label: 'topBar.link.tours',
        permissions: [{ subject: 'TOURS', scope: 'SEARCH' }],
        url: contentRoutePaths[contentRoutes.TOURS_LIST],
      },
      {
        label: 'topBar.link.communityStories',
        permissions: [{ subject: 'STANDALONE_STORIES', scope: 'SEARCH' }],
        url: contentRoutePaths[contentRoutes.COMMUNITY_STORIES_LIST],
      },
      {
        label: 'topBar.link.thingsToDo',
        permissions: [{ subject: 'TAGS_AND_THINGS_TO_DO', scope: 'SEARCH' }],
        url: contentRoutePaths[contentRoutes.THINGS_TO_DO_LIST],
      },
      {
        label: 'topBar.link.reviews',
        url: contentRoutePaths[contentRoutes.REVIEW_LIST],
        permissions: [{ subject: 'RATING', scope: 'SEARCH' }],
        urlMatchPattern: [contentRoutePaths[contentRoutes.REVIEW_LIST]],
      },
      {
        label: 'topBar.link.gallery',
        url: contentRoutePaths[contentRoutes.GALLERY],
        permissions: [{ subject: 'PHOTOS', scope: 'SEARCH' }],
        urlMatchPattern: [contentRoutePaths[contentRoutes.GALLERY]],
      },
      {
        label: 'topBar.link.pins',
        url: contentRoutePaths[contentRoutes.PINS_LIST],
        permissions: [{ subject: 'PIN', scope: 'SEARCH' }],
        urlMatchPattern: [contentRoutePaths[contentRoutes.PINS_LIST]],
      },
    ],
  },
  {
    label: 'topBar.group.management',
    url: authenticatedRoutePaths[authenticatedRoutes.MANAGEMENT],
    elements: [
      {
        label: 'topBar.link.moderation',
        url: managementRoutePaths[managementRoutes.MODERATION_LIST],
        permissions: [{ subject: 'MODERATION', scope: 'SEARCH' }],
        urlMatchPattern: [managementRoutePaths[managementRoutes.MODERATION_LIST]],
      },
      {
        label: 'topBar.link.voucherBatches',
        url: managementRoutePaths[managementRoutes.VOUCHER_BATCH_LIST],
        permissions: [{ subject: 'VOUCHERS', scope: 'SEARCH' }],
        urlMatchPattern: [managementRoutePaths[managementRoutes.VOUCHER_BATCH_LIST]],
      },
      {
        label: 'topBar.link.smartMaps',
        url: managementRoutePaths[managementRoutes.SMARTMAPS_LIST],
        permissions: [{ subject: 'SMART_MAPS', scope: 'SEARCH' }],
        urlMatchPattern: [managementRoutePaths[managementRoutes.SMARTMAPS_LIST]],
      },
      {
        label: 'topBar.link.mapAttribution',
        url: managementRoutePaths[managementRoutes.MAP_ATTRIBUTION_LIST],
        permissions: [{ subject: 'ATTRIBUTIONS', scope: 'SEARCH' }],
        urlMatchPattern: [managementRoutePaths[managementRoutes.MAP_ATTRIBUTION_LIST]],
      },
    ],
  },
  {
    label: 'topBar.group.reporting',
    url: authenticatedRoutePaths[authenticatedRoutes.REPORTING],
    elements: [
      {
        label: 'topBar.link.routeAnalytics',
        url: reportingPaths[ReportingRoutes.ROUTE_ANALYTICS],
        permissions: [{ subject: 'ROUTES', scope: 'VIEW_BASIC_STATS' }],
        urlMatchPattern: [reportingPaths[ReportingRoutes.ROUTE_ANALYTICS]],
      },
      {
        label: 'topBar.link.tourAnalytics',
        url: reportingPaths[ReportingRoutes.TOUR_ANALYTICS],
        permissions: [{ subject: 'ENTITLEMENTS', scope: 'VIEW_BASIC_STATS' }],
        urlMatchPattern: [reportingPaths[ReportingRoutes.TOUR_ANALYTICS]],
      },
    ],
  },
];
