import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/common/components/icon';
import { FileType } from '../../models';
import { styles, editorCardModeStyle } from './styles';

export type PreviewPlaceholderProps = {
  fileType?: FileType;
  multiple?: boolean;
  editorCardMode?: boolean;
};

export const PreviewPlaceholder: FC<PreviewPlaceholderProps> = ({ fileType, multiple, editorCardMode }) => {
  const [t] = useTranslation();

  const icon = useMemo(() => {
    if (editorCardMode) {
      return <Icon name='mdi-download' />;
    }

    switch (fileType) {
      case FileType.Map:
      case FileType.Gpx:
        return <Icon name='mdi-route-black' />;

      case FileType.Image:
        return <Icon name='mdi-image' />;

      case FileType.Other:
      default:
        return <Icon name='mdi-attach-file' />;
    }
  }, [fileType, editorCardMode]);

  return (
    <div css={editorCardMode ? editorCardModeStyle : styles}>
      <div className='icon-wrapper'>{icon}</div>
      <div className='text'>{t(multiple ? 'label.dragAndDropFiles' : 'label.dragAndDropFile')}</div>
    </div>
  );
};
