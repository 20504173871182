import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isObject from 'lodash/isObject';

import { styles } from './styles';
import type { ErrorMessageProps } from '.';

export const ErrorMessage: FC<ErrorMessageProps> = ({ message, ...props }) => {
  const [t] = useTranslation();

  const parsedMessage = useMemo(() => (isObject(message) ? t(message.key, message.values) : t(message)), [message, t]);

  return (
    <div css={styles} {...props}>
      {parsedMessage}
    </div>
  );
};
