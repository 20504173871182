import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import type { PinsListVM, PinsParams } from '@/models/pins';
import { type FeatureStateType } from '../../helpers';
import { pinsActions } from '../actions';

export type pinsListState = FetchState<PinsListVM, PinsParams>;

const INITIAL_STATE: pinsListState = getFetchInitialState<PinsListVM, PinsParams>();

const reducer = createReducer<pinsListState, pinsActions>(INITIAL_STATE)
  .handleAction(pinsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(pinsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(pinsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload));

export const pinsListReducer = { list: reducer };
export type pinsListReducer = FeatureStateType<typeof pinsListReducer>;
