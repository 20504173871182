import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { deactivationReasonsReducer } from './reducer';

type MakeGetDeactivationReasonsData = deactivationReasonsReducer['deactivationReasons']['data'];

const getDeactivationReasons = (state: deactivationReasonsReducer) => state.deactivationReasons;

export const makeGetDeactivationReasonsData = () =>
  createSelector<deactivationReasonsReducer, deactivationReasonsReducer['deactivationReasons'], MakeGetDeactivationReasonsData>(
    getDeactivationReasons,
    ({ data }) => data
  );
export const makeGetDeactivationReasonsStatus = () =>
  createSelector<deactivationReasonsReducer, deactivationReasonsReducer['deactivationReasons'], ReturnType<typeof getDataStatus>>(
    getDeactivationReasons,
    getDataStatus
  );
