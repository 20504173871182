import { type SelectOption } from '@/common/components/form-controls-deprecated';

type DeactivationReasonsResponse = {
  id: string;
  displayName: string;
  translationId: string;
  active: boolean;
};

export type DeactivationReasonsListResponse = DeactivationReasonsResponse[];

export interface DeactivationReasonsDictionaryElementVM extends SelectOption {}
export class DeactivationReasonsDictionaryElementVM {
  constructor({ id, displayName }: DeactivationReasonsResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface DeactivationReasonsDictionaryVM {
  list: DeactivationReasonsDictionaryElementVM[];
}

export class DeactivationReasonsDictionaryVM {
  constructor(list: DeactivationReasonsListResponse) {
    this.list = (list || []).map(element => new DeactivationReasonsDictionaryElementVM(element));
  }
}
