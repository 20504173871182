import { arrayValidator } from './array-validators';
import { createMixedValidator } from './mixed-validators';

export const selectValidator = <T extends {}>() => {
  //@ts-ignore
  const mixedSchema = createMixedValidator((input): input is T => true);
  return {
    single: () => mixedSchema,
    multiple: () => arrayValidator.of(mixedSchema),
  };
};
