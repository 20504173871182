import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, type DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { type DeactivationReasonsDictionaryVM } from '@/models/deactivation-reason';
import { type FeatureStateType } from '../helpers';
import { deactivationReasonsActions } from './actions';

type deactivationReasonsState = DictionaryState<DeactivationReasonsDictionaryVM>;

const INITIAL_STATE: deactivationReasonsState = getDictionaryInitialState<DeactivationReasonsDictionaryVM>();

const reducer = createReducer<deactivationReasonsState, deactivationReasonsActions>(INITIAL_STATE)
  .handleAction(deactivationReasonsActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(deactivationReasonsActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(deactivationReasonsActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(deactivationReasonsActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(deactivationReasonsActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const deactivationReasonsReducer = { deactivationReasons: reducer };
export type deactivationReasonsReducer = FeatureStateType<typeof deactivationReasonsReducer>;
