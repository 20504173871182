import * as Yup from 'yup';

import type { CommonValidationError } from './models';

export const ARRAY_LOCALE: Yup.LocaleObject['array'] = {
  min: ({ min }) => ({ key: min === 1 ? 'validation.array.atLeastOne' : 'validation.array.min', values: { min } } as CommonValidationError),
  max: ({ max }) => ({ key: 'validation.array.max', values: { max } } as CommonValidationError),
};

export const arrayValidator = Yup.array().typeError('validation.array.type');
