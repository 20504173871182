import { useState, useRef, MutableRefObject, useEffect, Dispatch, SetStateAction } from 'react';
import { TFunction } from 'i18next';
import isArray from 'lodash/isArray';
import pickBy from 'lodash/pickBy';
import { browserName, browserVersion } from 'react-device-detect';

import { Scope, Subject, Realm } from '@/core/models/user-roles';
import { i18n } from '@/core/services/i18n';
import { StatusWrapperStatus } from '../components/status-wrapper';
import { getEnv } from './environment';

const env = getEnv();

export const getWindowHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
    window.innerHeight,
    window.screen.height
  );

export const getWindowWidth = () =>
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
    window.innerWidth,
    window.screen.width
  );

export const getLocationOrigin = () => `${typeof location.origin === 'undefined' ? `${location.protocol}//${location.host}` : location.origin}`;

export const replaceIdInPath = (path: string, id: string, decode: boolean = true) => path.replace(/:id/g, decode ? btoa(encodeURIComponent(id)) : id);

type DataList = { list?: any[] };

export const getDataStatus = ({ data, isLoading, error }: { data: any | any[]; isLoading: boolean; error: any }): StatusWrapperStatus => {
  if (isLoading) {
    return 'loading';
  }

  if (error?.code === 404) {
    return 'not-found';
  }

  if (error?.code === 403) {
    return 'access-denied';
  }

  if (error !== undefined) {
    return 'failure';
  }

  if (data === undefined) {
    return 'not-searched';
  }

  if ((isArray(data) && data.length === 0) || (data !== undefined && isArray((data as DataList).list) && (data as DataList).list?.length === 0)) {
    return 'no-results';
  }

  return 'success';
};

const defaultDataStatuses: StatusWrapperStatus[] = ['loading', 'access-denied', 'not-found', 'failure', 'no-results', 'success'];

export const mergeDataStatues = (statuses: StatusWrapperStatus[], orderedStatuses: StatusWrapperStatus[] = defaultDataStatuses): StatusWrapperStatus => {
  for (const entity of orderedStatuses) {
    if (statuses.includes(entity)) {
      return entity;
    }
  }

  return 'success';
};

export const getReturnValue = <T>(value: ((...args: any[]) => T) | T, ...args: any[]): T => (value instanceof Function ? value(...args) : value);

export const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

export const useInstanceValue = <T = any>(initialValueFunc: () => T) => {
  const INITIAL_VALUE = 'INITIAL_VALUE';
  const ref = useRef<T | typeof INITIAL_VALUE>(INITIAL_VALUE);

  if (ref.current === INITIAL_VALUE) {
    ref.current = initialValueFunc();
  }

  return ref as MutableRefObject<T>;
};

export const usePrevious = <T>(value: T) => {
  const prev = useRef<T>(value);

  useEffect(() => {
    prev.current = value;
  }, [value]);

  return prev.current;
};

export const useRefState = <T>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const ref = useRef(initialState);
  const setState = (value: T | ((prev: T) => T)) => {
    ref.current = getReturnValue(value, ref.current);
  };
  return [ref.current, setState];
};

export const useDerivedState = <T>(
  value: T | (() => T),
  effect?: (state: T, setState: Dispatch<SetStateAction<T>>) => void
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(value);

  useEffect(() => {
    if (effect) return effect(state, setState);
    if (state !== getReturnValue(value)) {
      setState(value);
    }
  }, [value]);

  return [state, setState];
};

export const redirect = (url: string) => window.location.replace(url);

export type CheckUserPermissionScopes<T extends Subject> = { subject: T; realm?: Realm; scope?: Scope[T] };

export const createUserPermissionsRegExp = <T extends Subject>({ subject, realm, scope }: CheckUserPermissionScopes<T>): RegExp => {
  const DIVIDER_SIGN = '::';
  const ALL = '.+';
  // @TODO Remove devRealm const and if statement
  let devRealm = realm;
  if (realm === 'TEST' && env.REACT_APP_SHOW_TEST_FEATURES === 'true') {
    devRealm = undefined;
  }

  return new RegExp(`^${scope || ALL}${DIVIDER_SIGN}${devRealm || ALL}${DIVIDER_SIGN}${subject || ALL}$`);
};

export const checkUserPermissions = <T extends Subject>(data: CheckUserPermissionScopes<T>, permissions: string[]): boolean => {
  const regexp = createUserPermissionsRegExp(data);
  for (const permission of permissions) {
    if (regexp.test(permission)) {
      return true;
    }
  }

  return false;
};

export function pickNotEmptyStringValues<T extends {}>(params: T) {
  return pickBy(params, a => (typeof a === 'string' && a.length === 0 ? false : true));
}

export function getAppVersion() {
  // @ts-ignore
  return VERSION;
}

export function getBrowserData() {
  return `${browserName} ${browserVersion}`;
}

export const arrayReplace = <T>(array: T[], index: number, newValue: T) =>
  index >= 0 ? [...array.slice(0, index), newValue, ...array.slice(index + 1)] : array;

export const arrayRemove = <T>(array: T[], index: number) => (index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array);

/**
 * @function XOR implements XOR logical operator for boolean parameters
 */
export const XOR = (first: boolean, second: boolean): boolean => !!(+first ^ +second);

/**
 * @function XNOR implements inverted XOR logical operator for boolean parameters
 */
export const XNOR = (first: boolean, second: boolean): boolean => !XOR(first, second);

export const AND = (first: boolean, second: boolean): boolean => first && second;

export const getTranslation = (translationId?: string, fallback?: string | TFunction, fallbackTranslation?: string) =>
  translationId && i18n.exists(translationId) ? i18n.t(translationId) : getReturnValue(fallback, fallbackTranslation);

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const length = bytes.byteLength;
  for (let i = 0; i < length; i = i + 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
