import { type AutocompleteOption } from '../components/autocomplete';

export const AGE_RECOMMENDATIONS_OPTIONS: AutocompleteOption<number>[] = [
  {
    value: 4,
    label: '4+',
  },
  {
    value: 7,
    label: '7+',
  },
  {
    value: 11,
    label: '11+',
  },
  {
    value: 14,
    label: '14+',
  },
];
