import { createReducer } from 'typesafe-actions';

import { themeDark, themeLight, ThemeType } from '@/styles/theme';
import { THEME } from '@/styles/models';
import { FeatureStateType } from '../helpers';
import { interfaceActions } from './actions';
import { resetStoreAction } from '../middleware';

type CurrentTheme = {
  themeName: string;
  theme: ThemeType;
};

type interfaceState = {
  numberOfActiveActions: number;
  loadingSectionActive: boolean;
  initialUrl: string | undefined;
  currentTheme: CurrentTheme;
};

const getCurrentTheme = (): CurrentTheme => {
  let themeName: string;

  if (localStorage.getItem('currentTheme')) {
    themeName = localStorage.getItem('currentTheme') as string;
  } else if (window.matchMedia) {
    themeName = window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME.DARK : THEME.LIGHT;
  } else {
    themeName = THEME.LIGHT;
  }

  const theme: ThemeType = themeName === THEME.DARK ? themeDark : themeLight;

  return { themeName, theme };
};

const INITIAL_STATE: interfaceState = {
  numberOfActiveActions: 0,
  loadingSectionActive: false,
  initialUrl: undefined,
  currentTheme: getCurrentTheme(),
};

const reducer = createReducer<interfaceState, interfaceActions>(INITIAL_STATE)
  .handleAction(interfaceActions.increaseNumberOfActiveActions, state => ({ ...state, numberOfActiveActions: state.numberOfActiveActions + 1 }))
  .handleAction(interfaceActions.reduceNumberOfActiveActions, state => ({ ...state, numberOfActiveActions: state.numberOfActiveActions - 1 }))
  .handleAction(interfaceActions.toggleLoadingSection, (state, action) => ({ ...state, loadingSectionActive: action.payload }))
  .handleAction(interfaceActions.setInitialUrl, (state, action) => ({ ...state, initialUrl: action.payload }))
  .handleAction(interfaceActions.setTheme, (state, action) => {
    switch (action.payload) {
      case THEME.LIGHT:
        return {
          ...state,
          currentTheme: {
            themeName: THEME.LIGHT,
            theme: themeLight,
          },
        };
      case THEME.DARK:
        return {
          ...state,
          currentTheme: {
            themeName: THEME.DARK,
            theme: themeDark,
          },
        };
      default:
        return {
          ...state,
        };
    }
  })
  .handleAction(resetStoreAction, state => {
    return {
      ...state,
      currentTheme: getCurrentTheme(),
    };
  })
  .handleAction(interfaceActions.clearInitialUrl, state => ({ ...state, initialUrl: undefined }));

export const interfaceReducer = { interface: reducer };
export type interfaceReducer = FeatureStateType<typeof interfaceReducer>;
