import { of, Observable, defer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { stringify } from 'qs';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, Enum } from '@/common/utils';
import { type CustomFile } from '@/common/components/form/controls/file-input';
import {
  type CommunityStoriesListResponse,
  CommunityStoriesListVM,
  type CommunityStoriesParams,
  type CommunityStoryDetailsResponse,
  CommunityStoryDetailsVM,
  CommunityStoryElementVM,
} from '../../models/community-stories';
import { type ControlListDefs } from '../../pages/community-story-details/components/form/constants';

type CommunityStoryImageUploadResponse = {
  accelerometer: string;
  captureTime: string;
  createdAt: string;
  duration: string;
  gyroscope: string;
  id: string;
  likeCounter: number;
  location: string;
  magnetometer: string;
  provider: string;
  providerUrl: string;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: string;
  type: string;
  urlOriginal: string;
  urlResized: string;
  validationStatus: string;
};

type EditCommunityStoryParams = {
  communityStoryId: string;
  data: Omit<ControlListDefs, 'ageRecommendation'> & { ageRecommendation: string | number };
  fileData?: CommunityStoryImageUploadResponse | CustomFile;
};

const SendCommunityStoryResponseType = Enum('successful', 'uploadFileError', 'uploadDetailsError');
export type SendCommunityStoryResponseType = Enum<typeof SendCommunityStoryResponseType>;

export type SendCommunityStoryResponse = { type: SendCommunityStoryResponseType; id?: string };

type UploadCommunityStoryCoverParams = { files: File[] | CustomFile[] | undefined };

export type EditCommunityStoryEntityParams = EditCommunityStoryParams & UploadCommunityStoryCoverParams;

const CreateCommunityStoryResponseType = Enum('successful', 'uploadCoverError', 'uploadCommunityStoryError');
export type CreateCommunityStoryResponseType = { msg: Enum<typeof CreateCommunityStoryResponseType> };

const config = getEnv();

const CONTENT_COMMUNITY_STORIES_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories/search`;
const DELETE_COMMUNITY_STORY_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories/`;
const COMMUNITY_STORY_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories`;
const COMMUNITY_STORY_COVER_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/stories`;
const EDIT_COMMUNITY_STORY_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories`;
const NOTIFY_ABOUT_CHANGES_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories`;

export const getCommunityStoriesData = (data: CommunityStoriesParams) => {
  return httpClient()
    .authorized.post<CommunityStoriesListResponse>(
      CONTENT_COMMUNITY_STORIES_LIST_ENDPOINT,
      {
        createdBy: data.createdBy?.value,
        text: data.text,
        categoryIds: data.categoryIds,
        tagIds: data.tagIds,
        ageRecommendations: data.ageRecommendations,
      },
      {
        params: { ...omit(data, ['text', 'createdBy', 'shortResponse', 'categoryIds', 'tagIds', 'ageRecommendations']) },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new CommunityStoriesListVM({ data, VM: CommunityStoryElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );
};

export const deleteCommunityStory = (id: string, comment: string) => {
  return httpClient()
    .authorized.delete(`${DELETE_COMMUNITY_STORY_ENDPOINT}${id}`, {
      data: {
        comment,
      },
    })
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
};

export const getCommunityStoryDetailsData = (id: string) =>
  httpClient()
    .authorized.get<CommunityStoryDetailsResponse>(`${COMMUNITY_STORY_DETAILS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new CommunityStoryDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

const prepareCommunityStoryData = (data: Omit<ControlListDefs, 'ageRecommendation'> & { ageRecommendation: string | number }) => {
  return {
    ...omit(data, ['categories', 'latitude', 'longitude', 'author', 'email', 'visibility', 'creationDate', 'coverPhoto', 'tags']),
    location: {
      type: 'Point',
      coordinates: [
        typeof data.longitude === 'number' ? data.longitude.toFixed(7) : parseFloat(data.longitude).toFixed(7),
        typeof data.latitude === 'number' ? data.latitude.toFixed(7) : parseFloat(data.latitude).toFixed(7),
      ],
    },
    tagIds: data.tags,
    categoryId: data.categories[0],
    status: data.visibility ? 'PUBLISHED' : 'UNPUBLISHED',
  };
};

const editCommunityStory = ({ communityStoryId, data, fileData }: EditCommunityStoryParams) => {
  const customFile = data.coverPhoto?.[0] as CustomFile | undefined;

  const detailsData = {
    ...prepareCommunityStoryData(data),
    coverImageId: fileData?.id || customFile?.id,
  };

  return httpClient()
    .authorized.put<undefined>(`${EDIT_COMMUNITY_STORY_ENDPOINT}/${communityStoryId}`, { ...detailsData })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

const uploadCommunityStoryCover = ({ files }: UploadCommunityStoryCoverParams) => {
  if (files !== undefined) {
    const formData = new FormData();
    formData.append('file', files[0] as Blob);
    formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
    return httpClient()
      .authorized.post<CommunityStoryImageUploadResponse>(COMMUNITY_STORY_COVER_UPLOAD_ENDPOINT, formData)
      .pipe(
        map(({ data, status }) => {
          if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
            return data;
          }
          throw undefined;
        }),
        catchError(e => of(new CommonError({ code: '500', message: e })))
      );
  }
};

export const editCommunityStoryEntity = ({ data, communityStoryId, files }: EditCommunityStoryEntityParams): Observable<CreateCommunityStoryResponseType> =>
  defer(() => {
    if (!isNil(files) && files[0] instanceof File) {
      return uploadCommunityStoryCover({ files });
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ msg: CreateCommunityStoryResponseType.uploadCoverError });
      }

      return editCommunityStory({ data, communityStoryId, fileData: response }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return { msg: CreateCommunityStoryResponseType.uploadCommunityStoryError };
          }

          return { msg: CreateCommunityStoryResponseType.successful };
        })
      );
    })
  );

export const notifyAboutChangesInCommunityStory = (id: string, comment: string) => {
  return httpClient()
    .authorized.post<undefined>(`${NOTIFY_ABOUT_CHANGES_ENDPOINT}/${id}/modified-notifications`, {
      comment,
    })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
