import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { type GallerySourceDictionaryVM } from '@/models/gallery';
import { FeatureStateType } from '../../helpers';
import { galleryActions } from '../actions';

type sourceState = DictionaryState<GallerySourceDictionaryVM>;

const INITIAL_STATE: sourceState = getDictionaryInitialState<GallerySourceDictionaryVM>();

const reducer = createReducer<sourceState, galleryActions>(INITIAL_STATE)
  .handleAction(galleryActions.source.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(galleryActions.source.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(galleryActions.source.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(galleryActions.source.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(galleryActions.source.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const sourceDictionaryReducer = { source: reducer };
export type sourceDictionaryReducer = FeatureStateType<typeof sourceDictionaryReducer>;
