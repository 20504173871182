import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { type FileRejection, useDropzone } from 'react-dropzone';

import { useDerivedState } from '@/common/utils';
import { ControlContainer } from './components/control-container';
import { DropContainer } from './components/drop-container';
import { type BasicFileInputProps, FileSize, FileType, type OnDrop } from './models';
import { isCustomFile, FileInputPreset, OTHER_MIME_TYPE } from './constants';
import { styles } from './styles';

export const BasicFileInput = forwardRef<any, BasicFileInputProps>(
  (
    {
      value,
      disabled,
      name,
      multiple = false,
      fileType: overrideFileType,
      accept: overrideAccept,
      preset = FileType.Other,
      imageSrc,
      fileName,
      max,
      alterMessage,
      buttonProps,
      onChange,
      onFileChange,
      onReject,
      onDownload,
      dropzoneOptions,
      large,
      higher,
      inputProps,
      downloadIconProps,
      controlContainerProps,
      infoSectionProps,
      dropZoneRootProps,
      dropContainerWrapperProps,
      imagePreviewProps,
      editorCardMode,
      previewText,
      trigger,
    },
    ref
  ) => {
    const [coverImageUrl, setCoverImageUrl] = useState<string | undefined>();
    const [uploadedFileName, setUploadedFileName] = useState<string | undefined>();
    const [hasValue, setHasValue] = useDerivedState<boolean>(!!value?.[0]);

    const showDownload = useMemo(() => !!value?.[0] && isCustomFile(value[0]), [value]);

    const { accept, fileType, mimeType } = useMemo(
      () => ({
        accept: overrideAccept || FileInputPreset[preset].accept,
        fileType: overrideFileType || FileInputPreset[preset].fileType,
        mimeType: OTHER_MIME_TYPE || FileInputPreset[preset].mimeType,
      }),
      [preset, overrideAccept, overrideFileType]
    );

    const maxSize = useMemo(() => (max ? (typeof max === 'number' ? new FileSize(max) : new FileSize(max.size, max.unit)) : undefined), [max]);

    const handleOnChange = useCallback(
      (files: File[] | FileList) => {
        if (!files || !files[0]) return;

        if (fileType === FileType.Image && !multiple) setCoverImageUrl(URL.createObjectURL(files[0]));
        if (!multiple) setUploadedFileName(files[0].name);
        setHasValue(true);

        onChange?.(Array.from(files));

        onFileChange?.(Array.from(files));
        trigger?.(name);
      },
      [name, multiple, fileType, onChange, onFileChange, trigger, setHasValue]
    );

    const handleOnReject = useCallback(
      (fileRejections: FileRejection[]) => {
        if (!fileRejections.length) return;

        if (onReject) return onReject(fileRejections);
      },
      [onReject]
    );

    const onDrop = useCallback<OnDrop<File>>(
      (acceptedFiles, fileRejections) => {
        handleOnChange(acceptedFiles);
        handleOnReject(fileRejections);
      },
      [handleOnChange, handleOnReject]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      disabled,
      multiple,
      accept: accept && { [mimeType]: accept },
      maxSize: maxSize?.Bytes,
      ...(dropzoneOptions || {}),
      onDrop,
    });

    const getPreviewProps = useCallback(() => {
      const file = value?.[0] && (isCustomFile(value[0]) ? { name: value[0].name, path: value[0].path } : { name: value[0].name, path: '' });

      if (previewText) {
        return {
          fileName: previewText,
        };
      }

      switch (fileType) {
        case FileType.Image:
          if (coverImageUrl || uploadedFileName)
            return {
              imageSrc: coverImageUrl,
              fileName: uploadedFileName,
            };
          if (file && (file.name || file.path))
            return {
              imageSrc: file.path,
              fileName: file.name,
            };
        case FileType.Map:
        case FileType.Gpx:
          if (file?.name)
            return {
              fileName: file.name,
            };

          if (imageSrc)
            return {
              imageSrc,
            };
        case FileType.Other:
        default:
          if (uploadedFileName)
            return {
              fileName: uploadedFileName,
            };
          if (file && file.name)
            return {
              fileName: file.name,
            };
          return {
            fileName,
          };
      }
    }, [value, imageSrc, fileName, fileType, coverImageUrl, uploadedFileName, previewText]);

    return (
      <div css={styles} ref={ref}>
        <DropContainer
          getRootProps={getRootProps}
          {...getPreviewProps()}
          fileType={fileType}
          showDownload={showDownload}
          isDragActive={isDragActive}
          disabled={disabled}
          large={large}
          higher={higher}
          multiple={multiple}
          onDownload={onDownload}
          downloadIconProps={downloadIconProps}
          dropZoneRootProps={dropZoneRootProps}
          dropContainerWrapperProps={dropContainerWrapperProps}
          imagePreviewProps={imagePreviewProps}
          editorCardMode={editorCardMode}
        >
          <input {...getInputProps({ name: name || 'file-input', ...inputProps })} />
        </DropContainer>

        <ControlContainer
          buttonProps={{ onClick: open, ...buttonProps }}
          hasValue={hasValue}
          max={maxSize?.MegaBytes}
          accept={accept}
          alterMessage={alterMessage}
          multiple={multiple}
          disabled={disabled}
          controlContainerProps={controlContainerProps}
          infoSectionProps={infoSectionProps}
          editorCardMode={editorCardMode}
        />
      </div>
    );
  }
);
