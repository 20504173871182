import * as Yup from 'yup';

import { CommonValidationError } from './models';

export const MIXED_LOCALE: Yup.LocaleObject['mixed'] = {
  required: 'validation.mixed.required',
  default: ({ type }) => ({ key: 'validation.mixed.default', values: { type } } as CommonValidationError),
};

export const mixedValidator = Yup.mixed();

export const createMixedValidator = <T extends {}>(spec?: Yup.MixedOptions<T> | Yup.MixedTypeGuard<T> | undefined) => Yup.mixed<T>(spec);
