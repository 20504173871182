// TODO: change import to @common/components/form when Option type will be available
import { Option } from '@/common/components/form-controls-deprecated';
import { Enum } from '../utils';
import { type RadioOption } from '../components/form/controls/radio-group';

export const YesNoFilterValues = Enum('YES', 'NO');
export type YesNoFilterValues = Enum<typeof YesNoFilterValues>;

export const YesNoFilterOptions: Option[] = [
  { label: 'Yes', value: YesNoFilterValues.YES },
  { label: 'No', value: YesNoFilterValues.NO },
];

export const DeleteActiveFilterValues = Enum('ACTIVE', 'DELETED');
export type StatusFilterValues = Enum<typeof DeleteActiveFilterValues>;

export const DeleteActiveFilterOptions: RadioOption[] = [
  { label: 'Active', value: DeleteActiveFilterValues.ACTIVE },
  { label: 'Deleted', value: DeleteActiveFilterValues.DELETED },
];
