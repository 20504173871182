import React, { FC, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { components, DropdownIndicatorProps, GroupBase, ClearIndicatorProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';

import { CommonError } from '@/common/utils';
import { useReactSelectStyles, useStyles } from '@/styles/hooks';
import { Icon } from '../../icon';
import { selectStyles, dropdownIconStyle, noOptionTextStyle } from './styles';

export type AsyncSelectOption<T = string | number> = {
  value: T;
  label: string;
};

export type AsyncSelectResponse = {
  list: AsyncSelectOption[];
};

const NO_OPTION_PLACEHOLDER = '-----';
const DEBOUNCE_TIME = 250;

export type SelectOption<T = string | number> = {
  value: T;
  label: string;
  translationId?: string;
};

export type SelectValue = {
  value: string | number;
  label: string;
};

export type SelectProps = {
  options?: SelectOption[];
  onChange?: (value: SelectOption) => void;
  disabled?: boolean;
  className?: string;
  value?: string | number | SelectOption;
  name?: string;
  onBlur?: Function;
  placeholder?: string;
  withEmptyOption?: boolean;
  unitInput?: boolean;
  loadOptionService?: (name: string) => Observable<CommonError | AsyncSelectResponse>;
  clearable?: boolean;
};

export const Select: FC<SelectProps> = ({ options = [], placeholder, withEmptyOption, disabled, onChange, value, unitInput, clearable, loadOptionService }) => {
  const [t, i18n] = useTranslation();
  const { styles } = useReactSelectStyles(selectStyles);
  const [isInputDirty, setIsInputDirty] = useState<boolean>(false);
  const [cachedOptions, setCachedOptions] = useState<SelectOption[]>([]);
  const NoOptionTextStyle = useStyles(noOptionTextStyle);

  const renderOptions = useMemo(() => {
    const optionsList = options.map(({ label, value, translationId }) => ({
      value,
      label: translationId !== undefined && i18n.exists(translationId) ? t(translationId) : label,
    }));

    return withEmptyOption ? [{ value: '', label: NO_OPTION_PLACEHOLDER }, ...optionsList] : optionsList;
  }, [options, t, withEmptyOption]);

  const handleOnChange = useCallback(
    selectedOption => {
      if (onChange !== undefined) {
        onChange(selectedOption === null || selectedOption.value === '' ? undefined : selectedOption);
      }
    },
    [onChange]
  );

  const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon
          name='mdi-chevron-down'
          className={props.selectProps.menuIsOpen ? 'dropdown-icon dropdown-icon--open' : 'dropdown-icon'}
          css={dropdownIconStyle}
        />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: ClearIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) => {
    return (
      <components.ClearIndicator {...props}>
        <Icon name='mdi-close' className={'clear-icon'} />
      </components.ClearIndicator>
    );
  };

  const filterOptions = useCallback<(inputValue: string) => SelectOption[]>(
    inputValue => {
      return renderOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    },
    [renderOptions]
  );

  const handleLoadOptions = useCallback<(inputValue: string, callback: (options: SelectOption[]) => void) => void>(
    (inputValue, callback) => {
      callback(filterOptions(inputValue));
    },
    [renderOptions]
  );

  const handleInputChange = useCallback((newValue: string) => {
    newValue === '' ? setIsInputDirty(false) : setIsInputDirty(true);
  }, []);

  const handleLoadAsyncOptions = useCallback<(value: string) => Promise<AsyncSelectOption[]> | undefined>(
    value => {
      if (loadOptionService !== undefined) {
        return loadOptionService(value)
          .pipe(
            map((response: AsyncSelectResponse | CommonError) => {
              if (response instanceof CommonError) {
                return [];
              }
              setCachedOptions(response.list);
              return response.list;
            })
          )
          .toPromise();
      }
      return undefined;
    },
    [loadOptionService]
  );

  const debounceAsyncLoadOptions = handleLoadAsyncOptions ? debounce(handleLoadAsyncOptions, DEBOUNCE_TIME) : undefined;

  const getSelectValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    } else if (typeof value === 'object') {
      return value;
    }
    return options.find(option => option.value === value);
  }, [value]);

  return (
    <AsyncSelect
      value={getSelectValue || null}
      onInputChange={handleInputChange}
      loadOptions={debounceAsyncLoadOptions ? debounceAsyncLoadOptions : handleLoadOptions}
      defaultOptions={loadOptionService ? (!value ? [] : cachedOptions) : options}
      isDisabled={disabled}
      onChange={handleOnChange}
      styles={styles}
      placeholder={placeholder || NO_OPTION_PLACEHOLDER}
      components={{ DropdownIndicator, ClearIndicator }}
      noOptionsMessage={() => <div css={NoOptionTextStyle.styles}>{isInputDirty ? t('label.noOptions') : t('label.startTyping')}</div>}
      menuPlacement='auto'
      isClearable={clearable}
      backspaceRemovesValue={false}
      isSearchable={loadOptionService !== undefined}
      //@ts-ignore
      unitInput={unitInput}
      menuPortalTarget={document.body}
      menuPosition='fixed'
      menuShouldScrollIntoView={false}
    />
  );
};
