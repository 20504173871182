import { createTheme, adaptV4Theme } from '@mui/material';

import { Styles, theme, constants } from '@/styles';

export const tooltipStyle = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: theme.primary,
          borderRadius: constants.borderRadius.small,
          padding: `${constants.offset.small}px ${constants.offset.medium}px`,
          maxWidth: 450,
        },
      },
    },
  })
);

export const childWrapperStyle: Styles = {
  display: 'flex',
};

export const tooltipContentStyles: Styles = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '.tooltip-icon': {
    color: theme.textWhite,
    fontSize: constants.fontSize.icon,
    marginRight: constants.offset.small,
    paddingTop: 7,
  },

  '.tooltip-message': {
    color: theme.textWhite,
    fontSize: constants.fontSize.h4,
    padding: '2px 0',
    width: '100%',
    lineHeight: 'normal',
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
  },
};
