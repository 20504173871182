import * as Yup from 'yup';

import { STRING_LOCALE } from './string-validators';
import { NUMBER_LOCALE } from './number-validators';
import { MIXED_LOCALE } from './mixed-validators';
import { ARRAY_LOCALE } from './array-validators';
import type { CustomFile, FileSize } from '../../controls/file-input';

export const setFormLocalization = () =>
  Yup.setLocale({
    string: STRING_LOCALE, // eslint-disable-line id-denylist,
    number: NUMBER_LOCALE, // eslint-disable-line id-denylist,
    mixed: MIXED_LOCALE,
    array: ARRAY_LOCALE,
  });

export const checkFileExtension = (supportedFormats: string[], file: File | CustomFile | undefined): boolean => {
  if (file !== undefined && file.name !== undefined && file instanceof File) {
    const fileName: string = file.name;
    const fileExtension: string | undefined = fileName.split('.').pop();

    if (fileExtension !== undefined) {
      return supportedFormats.includes(fileExtension.toLowerCase());
    }
  }

  return true;
};

export const checkFileSize = (maxSize: FileSize, file: File | undefined): boolean => {
  if (file !== undefined && file.size !== undefined) {
    return file.size <= maxSize.Bytes;
  }

  return true;
};
