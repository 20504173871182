import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { type Styles as RJStyles } from 'react-joyride';
import { type GroupBase, type StylesConfig } from 'react-select';
import { type Theme } from '@mui/material';
import { css, type Interpolation, type SerializedStyles } from '@emotion/core';

import { makeGetCurrentTheme } from '@/store/interface';
import { type SelectValue } from '@/common/components/form-controls-deprecated';
import { type StyleFunction } from './models';

const getCurrentTheme = makeGetCurrentTheme();

type UseStylesData = {
  styles: SerializedStyles[] | any;
  themeName: string;
};
export const useStyles = <R extends Interpolation = Interpolation>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);

  return useMemo(() => {
    return { styles: [css(styleFunctions({ theme, themeName }))] as const, themeName };
  }, [styleFunctions, theme, themeName]);
};

export const useMuiStyles = <R extends Theme = Theme>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);

  return useMemo(() => {
    return { styles: styleFunctions({ theme, themeName }), themeName };
  }, [styleFunctions, theme, themeName]);
};

export const useJoyrideStyles = <R extends RJStyles = RJStyles>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);

  return useMemo(() => {
    return { styles: styleFunctions({ theme, themeName }), themeName };
  }, [styleFunctions, theme, themeName]);
};

export const useReactSelectStyles = <R extends StylesConfig<SelectValue, false, GroupBase<SelectValue>>>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);

  return useMemo(() => {
    return { styles: styleFunctions({ theme, themeName }), themeName };
  }, [styleFunctions, theme, themeName]);
};
