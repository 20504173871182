import { switchMap, filter } from 'rxjs/operators';
import { type Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getDeactivationReasonDictionaryData } from '@/features/accounts/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { deactivationReasonsActions } from './actions';

const getDeactivationReasonsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deactivationReasonsActions.request)),
    switchMap(() => {
      const state = state$.value.deactivationReasons;
      return dictionaryEffectHelper(() => getDeactivationReasonDictionaryData(), state, deactivationReasonsActions);
    })
  );

export const deactivationReasonsEpics = [getDeactivationReasonsList];
