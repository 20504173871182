import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { galleryListReducer } from './list';
import { statusDictionaryReducer } from './statuses';
import { applicationDictionaryReducer } from './applications';
import { modalImagesReducer } from './modal-images';
import { sourceDictionaryReducer } from './source';

export type galleryReducer = FeatureStateType<typeof galleryReducer>;

export const galleryReducer = {
  gallery: combineReducers({
    ...galleryListReducer,
    ...statusDictionaryReducer,
    ...applicationDictionaryReducer,
    ...modalImagesReducer,
    ...sourceDictionaryReducer,
  }),
};
