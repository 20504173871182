import React, { FCC, type HTMLAttributes, type MouseEvent, useEffect, useMemo, useRef } from 'react';
import { type DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IconButton, IconButtonLink, type IconButtonLinkProps, type IconButtonProps } from '@/common/components/icon-button';
import { Image, type ImageProps } from '@/common/components/image';
import { getClassName } from '@/styles';
import { useDerivedState } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { FileType } from '../../models';
import { DRAG_AREA_ID } from '../../constants';
import { PreviewPlaceholder, type PreviewPlaceholderProps } from '../preview-placeholder';
import { dropContainerStyles } from './styles';

const DEBOUNCE_TIME = 500;

export type DropContainerProps = {
  dropZoneRootProps?: HTMLAttributes<HTMLDivElement>;
  dropContainerWrapperProps?: HTMLAttributes<HTMLDivElement>;
  imagePreviewProps?: ImageProps;
  downloadIconProps?: Partial<IconButtonLinkProps> | Partial<IconButtonProps>;
  imageSrc?: string;
  fileName?: string;
  disabled?: boolean;
  large?: boolean;
  higher?: boolean;
  onDownload?: (event: MouseEvent) => void;
  editorCardMode?: boolean;
};

type Props = PreviewPlaceholderProps &
  DropContainerProps & {
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    showDownload: boolean;
    isDragActive: boolean;
  };

export const DropContainer: FCC<Props> = ({
  getRootProps,
  dropZoneRootProps,
  dropContainerWrapperProps,
  imagePreviewProps,
  downloadIconProps,
  isDragActive,
  imageSrc,
  fileName,
  fileType,
  multiple,
  showDownload,
  disabled,
  large,
  higher,
  onDownload,
  children,
  editorCardMode,
}) => {
  const [t] = useTranslation();
  const [isDownloadDisabled, setIsDownloadDisabled] = useDerivedState(disabled);
  const $disableDownload = useRef(new Subject<MouseEvent>());
  const { styles } = useStyles(dropContainerStyles);

  useEffect(() => {
    const subscription = $disableDownload.current.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(event => {
      onDownload?.(event);
      setIsDownloadDisabled(disabled);
    });

    return () => subscription.unsubscribe();
  }, [disabled, onDownload, setIsDownloadDisabled]);

  const preview = useMemo(() => {
    if (imageSrc)
      return (
        <Image className={getClassName('image-preview', { '--large': large, '--higher': higher })} src={imageSrc} title={fileName} {...imagePreviewProps} />
      );

    if (fileName)
      return (
        <span className={getClassName('file-name', { '--large': large, '--higher': higher })} title={fileName}>
          {fileName}
        </span>
      );

    return <PreviewPlaceholder fileType={fileType} multiple={multiple} editorCardMode={editorCardMode} />;
  }, [imageSrc, fileType, multiple, fileName, large, imagePreviewProps, higher, editorCardMode]);

  const download = useMemo(() => {
    if (!showDownload || multiple || disabled || downloadIconProps?.hidden) return null;

    const link = (fileType === FileType.Image && imageSrc) || '';
    const title = t(fileType === FileType.Image ? 'label.downloadImage' : 'label.downloadFile');

    const handleClick = (event: MouseEvent) => {
      $disableDownload.current.next(event);
      setIsDownloadDisabled(true);
    };

    if (link && !disabled && !downloadIconProps?.hidden)
      return (
        <IconButtonLink
          link={link}
          title={title}
          external
          className='download-button'
          iconName='mdi-download'
          disabled={isDownloadDisabled}
          onClick={handleClick}
          download
          {...(downloadIconProps as Partial<IconButtonLinkProps>)}
        />
      );

    if (fileType !== FileType.Image && onDownload)
      return (
        <IconButton
          className='download-button'
          iconName='mdi-download'
          title={title}
          disabled={isDownloadDisabled}
          onClick={handleClick}
          {...(downloadIconProps as Partial<IconButtonProps>)}
        />
      );

    return null;
  }, [showDownload, multiple, fileType, downloadIconProps, imageSrc, isDownloadDisabled, onDownload, t, disabled, setIsDownloadDisabled]);

  return (
    <div css={styles} {...dropContainerWrapperProps}>
      <div
        {...getRootProps(dropZoneRootProps)}
        id={DRAG_AREA_ID}
        className={getClassName(
          'drop-zone',
          { '--dragged': isDragActive, '--disabled': disabled, '--large': large, '--higher': higher, '--short': editorCardMode },
          [dropZoneRootProps?.className]
        )}
      >
        {children}
        {preview}
      </div>
      {download}
    </div>
  );
};
