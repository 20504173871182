import { type ActionType, type Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { type DeactivationReasonsDictionaryVM } from '@/models/deactivation-reason';

export const deactivationReasonsActions = createDictionaryActions(
  'FETCH_DECATIVATION_REASONS_DICTIONARY_REQUEST',
  'FETCH_DECATIVATION_REASONS_DICTIONARY_SUCCESS',
  'FETCH_DECATIVATION_REASONS_DICTIONARY_FAILURE',
  'FETCH_DECATIVATION_REASONS_DICTIONARY_CANCEL',
  'FETCH_DECATIVATION_REASONS_DICTIONARY_SILENT_REQUEST'
)<undefined, DeactivationReasonsDictionaryVM>();

export type deactivationReasonsActions = Action | ActionType<typeof deactivationReasonsActions>;
