import { of } from 'rxjs';
import omit from 'lodash/omit';
import { catchError, map } from 'rxjs/operators';
import qs from 'qs';

import {
  RoutesParams,
  RoutesListResponse,
  RoutesListVM,
  RoutesElementVM,
  RouteDetailsResponse,
  RouteDetailsVM,
  RoutesUpdateParamsList,
  routeDetailsParams,
  RoutesListResponseTemp,
  RoutesFilters,
  RoutesTotalCountResponse,
  OwnerType,
} from '@/features/content/models/routes';
import { httpClient } from '@/core/services/http-client';
import { YesNoFilterValues } from '@/common/constants/filter-constants';
import { getEnv, CommonError, pickNotEmptyStringValues, arrayBufferToBase64 } from '@/common/utils';
import { getRouteListDistanceParamValue, getRouteTypeCircular, mapTempRouteListResponse } from './functions';

const config = getEnv();

const CONTENT_ROUTE_LIST_PATH = '/route-api/v2/local/adminPanel/routes';
const USER_ROUTES_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/route-api/v2/adminPanel/routes`;
const DELETE_ROUTE_PATH = '/route-api/v1/routes/';
const UPDATE_ROUTE_DETAILS_PATH = '/route-api/v1/routes/';
const CONTENT_ROUTE_DETAILS_PATH = '/route-api/v2/adminPanel/routes/';
const UPLOAD_ROUTE_PATH = '/route-api/v1/routes/upload';
const RESTORE_ROUTE_PATH = '/route-api/v2/adminPanel/routes/restore';
const DELETE_ROUTES_PATH = '/route-api/v2/adminPanel/routes/delete';
const ROUTES_THUMBNAIL_ENDPOINT = `${config.REACT_APP_API_URL}/thumbnail-api/v3/routes`;
const ROUTES_TOTAL_COUNT_PATH = '/route-api/v2/local/adminPanel/routes/count';
const DOWNLOAD_GPX_ENDPOINT = `${config.REACT_APP_API_URL}/route-api/v1/routes`;

export const getRouteListData = ({ searchType, ...requestParams }: RoutesParams) => {
  if (requestParams.currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.get<RoutesListResponseTemp>(`${requestParams.currentRegionUrl}${CONTENT_ROUTE_LIST_PATH}`, {
      params: {
        ...pickNotEmptyStringValues(omit(requestParams, 'routeType' || 'hasPhotos')),
        minDistance: getRouteListDistanceParamValue(requestParams.minDistance),
        maxDistance: getRouteListDistanceParamValue(requestParams.maxDistance),
        createdBy: requestParams.createdBy?.value,
        organisationId: requestParams.organisationId?.value,
        visibility: (requestParams.visibilities?.length || 0) > 1 ? undefined : requestParams.visibilities,
        isOrganisational: requestParams.isOrganisational ? true : null,
        looped: getRouteTypeCircular(requestParams?.routeType),
        hasPhotos: requestParams.hasPhotos?.length !== 1 ? undefined : requestParams.hasPhotos[0] === YesNoFilterValues.YES ? true : false,
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new RoutesListVM({ data: mapTempRouteListResponse(data), VM: RoutesElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deleteRoute = (id: string, currentRegionUrl: string | undefined) => {
  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.delete<RoutesListResponse>(`${currentRegionUrl}${DELETE_ROUTE_PATH}${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
};

export const getRoutesDetailsData = (params: routeDetailsParams) => {
  if (params.currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.get<RouteDetailsResponse>(`${params.currentRegionUrl}${CONTENT_ROUTE_DETAILS_PATH}${params.id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new RouteDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );
};

export const getOSMapsRouteUrl = (id: string) => `${config.REACT_APP_OS_MAPS_URL}/route/${id}`;

export const replaceRouteGpx = (routeId: string, file: File, currentRegionUrl: string | undefined) => {
  const newGpxFile = new FormData();
  newGpxFile.append('file', file);
  newGpxFile.append('metadata', new Blob(['{}'], { type: 'application/json' }));

  return httpClient()
    .authorized.post(`${currentRegionUrl}${UPDATE_ROUTE_DETAILS_PATH}${routeId}?partialGeometryUpdate=true`, newGpxFile)
    .pipe(
      map(response => {
        if (response.status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError(e.data)))
    );
};

export const updateRouteDetails = (routeId: string, data: RoutesUpdateParamsList, currentRegionUrl: string | undefined) => {
  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.patch<undefined>(`${currentRegionUrl}${UPDATE_ROUTE_DETAILS_PATH}${routeId}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError(e.data)))
    );
};

export const uploadRoute = (file: File, currentRegionUrl: string | undefined) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));

  return httpClient()
    .authorized.post(`${currentRegionUrl}${UPLOAD_ROUTE_PATH}`, formData)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return true;
        }

        throw undefined;
      }),
      catchError(() => of(false))
    );
};

export const restoreRoutes = (ids: string[], currentRegionUrl: string | undefined) => {
  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.post<RoutesListResponse>(`${currentRegionUrl}${RESTORE_ROUTE_PATH}`, {
      routeIds: ids,
    })
    .pipe(
      map(({ status }) => {
        if (status === 201 || status === 204) {
          return undefined;
        }
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deleteRoutes = (ids: string[], currentRegionUrl: string | undefined) => {
  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.post<RoutesListResponse>(`${currentRegionUrl}${DELETE_ROUTES_PATH}`, {
      routeIds: ids,
    })
    .pipe(
      map(({ status }) => {
        if (status === 201 || status === 204) {
          return undefined;
        }
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getRouteThumbnailData = (id: string) =>
  httpClient()
    .authorized.get<any>(`${ROUTES_THUMBNAIL_ENDPOINT}/${id}`, { responseType: 'arraybuffer' })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          const image = arrayBufferToBase64(data);
          return `data:image/png;base64,${image}`;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getRoutesTotalCountData = (params: RoutesFilters) => {
  if (params.currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.get<RoutesTotalCountResponse>(`${params.currentRegionUrl}${ROUTES_TOTAL_COUNT_PATH}`, {
      params: {
        ...pickNotEmptyStringValues(omit(params, 'routeType' || 'hasPhotos')),
        minDistance: getRouteListDistanceParamValue(params.minDistance),
        maxDistance: getRouteListDistanceParamValue(params.maxDistance),
        createdBy: params.createdBy?.value,
        organisationId: params.organisationId?.value,
        isOrganisational: params.isOrganisational ? true : null,
        looped: getRouteTypeCircular(params?.routeType),
        hasPhotos: params.hasPhotos?.length !== 1 ? undefined : params.hasPhotos[0] === YesNoFilterValues.YES ? true : false,
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return data.totalRoutes;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getOSMapsRouteGPXFileLink = (id: string) =>
  httpClient()
    .authorized.get<string>(`${DOWNLOAD_GPX_ENDPOINT}/${id}`, {
      headers: {
        accept: 'application/gpx+xml',
      },
      responseType: 'blob',
    })
    .pipe(
      map(({ status, data }) => {
        if (status === 200 && data !== undefined) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const updateRouteOwner = (routeId: string, ownerData: OwnerType, currentRegionUrl: string | undefined) => {
  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  const data = {
    owner: ownerData,
  };

  return httpClient()
    .authorized.patch<undefined>(`${currentRegionUrl}${UPDATE_ROUTE_DETAILS_PATH}${routeId}/owner`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError(e.data)))
    );
};
