import { Dayjs } from 'dayjs';
import { type ApiPagination, type PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { type SelectOption } from '@/common/components/form-controls-deprecated';
import { type MediaStatus } from '@/common/components/photo-details-modal/components/bottom-bar';
import { type AutocompleteOption } from '@/common/components/autocomplete';

export type GalleryFilters = {
  text?: string;
  targetType?: string;
  targetTypes?: string[];
  reviewed?: string[];
  reported?: string[];
  validationStatus?: string[];
  application?: string[];
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  sources?: string[];
  createdBy?: AutocompleteOption;
};

export type GalleryParams = ApiPagination<GalleryFilters>;

type GalleryStatusResponse = {
  displayName: string;
  id: string;
};

export type GalleryStatusListResponse = GalleryStatusResponse[];

export interface GalleryStatusDictionaryElementVM extends SelectOption {}

export class GalleryStatusDictionaryElementVM {
  constructor({ displayName, id, ...props }: GalleryStatusResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface GalleryStatusDictionaryVM {
  list: GalleryStatusDictionaryElementVM[];
}

export class GalleryStatusDictionaryVM {
  constructor(list: GalleryStatusListResponse) {
    this.list = (list || []).map(element => new GalleryStatusDictionaryElementVM(element));
  }
}

type GalleryApplicationsResponse = {
  displayName: string;
  id: string;
};

export type GalleryApplicationsListResponse = GalleryApplicationsResponse[];

export interface GalleryApplicationsDictionaryElementVM extends SelectOption {}

export class GalleryApplicationsDictionaryElementVM {
  constructor({ displayName, id, ...props }: GalleryApplicationsResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface GalleryApplicationsDictionaryVM {
  list: GalleryApplicationsDictionaryElementVM[];
}

export class GalleryApplicationsDictionaryVM {
  constructor(list: GalleryApplicationsListResponse) {
    this.list = (list || []).map(element => new GalleryApplicationsDictionaryElementVM(element));
  }
}

export type AssociationsElement = {
  moderationRequestIds?: string[] | [];
  targetId?: string;
  targetType?: string;
  targetCreatorUserId?: string;
  targetProvider?: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type GalleryListEntityResponse = {
  id: string;
  metadata: {};
  origin: {
    creatorUserId: string;
    author: string;
    sourceApp?: string;
  };
  files: {
    small: {
      url: string;
      size: number;
    };
    medium: {
      url: string;
      size: number;
    };
    large: {
      url: string;
      size: number;
    };
  };
  type: string;
  validationStatus: string;
  validationDetails: {
    hasOffensiveLanguage: boolean;
    offensiveTerms: [];
    message: string;
    attempts: number;
    adult: boolean;
    racy: boolean;
  };
  likeCounter: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  ratingId: string;
  reported: boolean;
  reviewed: boolean;
  associations: {
    targetId: string;
    targetType: string;
    associations: AssociationsElement[] | [];
  };
};

export type GalleryListResponse = PageableListResponse<GalleryListEntityResponse>;

export type GalleryListRequest = GalleryFilters & ApiPagination;

export interface GalleryListElementVM extends GalleryListEntityResponse {}

export class GalleryListElementVM {
  constructor({ ...props }: GalleryListEntityResponse) {
    Object.assign(this, {
      ...props,
    });
  }
}

export class GalleryListVM extends PageableListVM<GalleryListElementVM, GalleryListResponse> {}

export type ModalImagesElement = {
  original: string;
  thumbnail: string;
  isReviewed: boolean;
  isReviewedStatus: MediaStatus;
  associations: AssociationsElement[] | [];
  targetId: string;
  targetType: string;
  deleteStatus: MediaStatus;
  isReported: boolean;
  mediaId: string;
  origin: {
    creatorUserId: string;
    author: string;
    sourceApp?: string;
  };
  createdAt: string;
};

export type ModalImagesList = ModalImagesElement[];

type GallerySourceResponse = {
  displayName: string;
  id: string;
};

export type GallerySourceListResponse = GallerySourceResponse[];

export interface GallerySourceDictionaryElementVM extends SelectOption {}

export class GallerySourceDictionaryElementVM {
  constructor({ displayName, id, ...props }: GallerySourceResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface GallerySourceDictionaryVM {
  list: GallerySourceDictionaryElementVM[];
}

export class GallerySourceDictionaryVM {
  constructor(list: GallerySourceListResponse) {
    this.list = (list || []).map(element => new GallerySourceDictionaryElementVM(element));
  }
}
