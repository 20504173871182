import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { stringify } from 'qs';

import { getEnv, CommonError, Enum } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import {
  OrganisationListVM,
  OrganisationListResponse,
  OrganisationsListParams,
  OrganisationElementVM,
  OrganisationDetailsResponse,
  OrganisationDetailsVM,
  AddOrganisationParams,
  UpdateOrganisationDetailsParams,
  OrganisationChangeImageResponse,
  UpdateOrganisationDetailsEntityParams,
  CreatedOrganisation,
  OrganisationAutocompleteListVM,
} from '@/models/organisations';
import { CustomFile } from '@/common/components/form/controls/file-input';

const ORGANISATION_ALREADY_EXISTS_ERROR_CODE_CREATE = 'OSCP-021';
const ORGANISATION_ALREADY_EXISTS_ERROR_CODE_UPDATE = 'OSCP-022';

const config = getEnv();

const ORGANISATIONS_DATA_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/organisations`;
const ORGANISATIONS_LOGO_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/organisations`;

export const getOrganisationsListData = (data: OrganisationsListParams) =>
  httpClient()
    .authorized.get<OrganisationListResponse>(ORGANISATIONS_DATA_ENDPOINT, {
      params: { ...data },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OrganisationListVM({ data, VM: OrganisationElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getOrganisationsAutocompleteOptionList = (name: string) =>
  httpClient()
    .authorized.get<OrganisationListResponse>(ORGANISATIONS_DATA_ENDPOINT, {
      params: { autocomplete: true, statuses: ['ACTIVE', 'INACTIVE'], name },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OrganisationAutocompleteListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getOrganisationsDetailsData = (id: string) =>
  httpClient()
    .authorized.get<OrganisationDetailsResponse>(`${ORGANISATIONS_DATA_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OrganisationDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export type UpdateOrganisationsDetailsParams = { organisationId: string; data: UpdateOrganisationDetailsParams };

const updateOrganisationsDetails = ({ organisationId, data }: UpdateOrganisationsDetailsParams) => {
  return httpClient()
    .authorized.put<undefined>(`${ORGANISATIONS_DATA_ENDPOINT}/${organisationId}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.data.code, message: e.data.message })))
    );
};

export type AddOrganisationsParams = { data: AddOrganisationParams };

const addOrganisation = ({ data }: AddOrganisationsParams) => {
  return httpClient()
    .authorized.post<CreatedOrganisation>(`${ORGANISATIONS_DATA_ENDPOINT}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return response.data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.data.code, message: e.data.message })))
    );
};

export type UploadOrganisationLogoParams = { files: File[] | CustomFile[] };

const uploadOrganisationLogo = ({ files }: UploadOrganisationLogoParams) => {
  const formData = new FormData();
  formData.append('file', files[0] as Blob);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<OrganisationChangeImageResponse>(ORGANISATIONS_LOGO_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export type UpdateOrganisationEntityParams = { data: UpdateOrganisationDetailsEntityParams; organisationId: string; files: File[] | CustomFile[] };
export const UpdateOrganizationResponseType = Enum('successful', 'uploadImageError', 'uploadDetailsError', 'organisationAlreadyExists');
export type UpdateOrganizationResponseType = Enum<typeof UpdateOrganizationResponseType>;

export const updateOrganisationEntity = ({ files, organisationId, data }: UpdateOrganisationEntityParams): Observable<UpdateOrganizationResponseType> =>
  defer(() => {
    if (files[0] instanceof File) {
      return uploadOrganisationLogo({ files });
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of(UpdateOrganizationResponseType.uploadImageError);
      }

      const updatedData: UpdateOrganisationDetailsParams = {
        ...data,
        logoImageId: response?.id ? response?.id : files[0] instanceof CustomFile ? files[0].id : '',
        logoImagePath: response?.urlOriginal ? response?.urlOriginal : files[0] instanceof CustomFile ? files[0].path : '',
      };

      return updateOrganisationsDetails({ organisationId, data: updatedData }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            if (response.code === ORGANISATION_ALREADY_EXISTS_ERROR_CODE_UPDATE) {
              return UpdateOrganizationResponseType.organisationAlreadyExists;
            }
            return UpdateOrganizationResponseType.uploadDetailsError;
          }

          return UpdateOrganizationResponseType.successful;
        })
      );
    })
  );

export type AddOrganisationEntityParams = AddOrganisationsParams & UploadOrganisationLogoParams;
export const AddOrganizationResponseType = UpdateOrganizationResponseType;
export type AddOrganizationResponseType = Enum<typeof UpdateOrganizationResponseType>;
export type SendOrganizationResponse = { type: UpdateOrganizationResponseType; id?: string };

export const addOrganisationEntity = ({ files, data }: AddOrganisationEntityParams): Observable<SendOrganizationResponse> =>
  defer(() => {
    if (files[0] instanceof File) {
      return uploadOrganisationLogo({ files });
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ type: AddOrganizationResponseType.uploadImageError });
      }

      const updatedData: UpdateOrganisationDetailsParams = {
        ...data,
        mobileDisplayName: data.name,
        priority: 0,
        logoImageId: response?.id ? response?.id : files[0] instanceof CustomFile ? files[0].id : '',
        logoImagePath: response?.urlOriginal ? response?.urlOriginal : files[0] instanceof CustomFile ? files[0].path : '',
      };

      return addOrganisation({ data: updatedData }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            if (response.code === ORGANISATION_ALREADY_EXISTS_ERROR_CODE_CREATE) {
              return { type: AddOrganizationResponseType.organisationAlreadyExists };
            }
            return { type: AddOrganizationResponseType.uploadDetailsError };
          }

          return { type: AddOrganizationResponseType.successful, id: response.id };
        })
      );
    })
  );

export const deactivateOrganisation = (id: string) =>
  httpClient()
    .authorized.delete(`${ORGANISATIONS_DATA_ENDPOINT}/${id}/deactivation`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return { status: 200 };
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
