import React from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { Field } from '../../components/field';
import { BasicFileInput } from './basic-file-input';
import { type FileInputProps } from './models';

export const FileInput = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  field,
  name,
  label,
  labelIcon,
  isFocusOnLabelEnabled,
  className,
  link,
  tooltipMessage,
  trigger,
  ...rest
}: FileInputProps<TFieldValues, TName>) => {
  return (
    <Field {...field} {...{ label, labelIcon, isFocusOnLabelEnabled, className, tooltipMessage, link }} isFocusOnLabelEnabled={false}>
      {({ controller: { field } }) => <BasicFileInput {...rest} {...field} trigger={trigger} />}
    </Field>
  );
};
