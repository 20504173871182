import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import pick from 'lodash/pick';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import {
  PinCategoriesDictionaryVM,
  PinCategoriesListResponse,
  type PinDetailsResponse,
  PinDetailsVM,
  PinsElementVM,
  type PinsListResponse,
  PinsListVM,
  type PinsParams,
} from '@/models/pins';
import { YesNoFilterValues } from '@/common/constants/filter-constants';

const config = getEnv();

const PIN_ENDPOINT = `${config.REACT_APP_API_URL}/outdoor-activity-api/admin/v1/pins`;
const PIN_CATEGORIES_ENDPOINT = `${config.REACT_APP_API_URL}/outdoor-activity-api/v1/pins/categories`;

export const getPinList = (payload: PinsParams) => {
  const params = {
    name: payload.name,
    dateFrom: payload.dateFrom,
    dateTo: payload.dateTo,
    categories: payload.categories,
    region: payload.region,
    deleted: payload.deleted,
    userId: payload.user?.value,
    reported:
      payload.reported === undefined || payload.reported?.length === 0 || payload.reported?.length === 2
        ? undefined
        : payload.reported[0] === YesNoFilterValues.YES,
  };

  return httpClient()
    .authorized.post<PinsListResponse>(`${PIN_ENDPOINT}/queries`, params, {
      params: pick(payload, 'page', 'size', 'sort'),
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PinsListVM({ data, VM: PinsElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deletePin = (id: string) => {
  return httpClient()
    .authorized.delete(`${PIN_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getPinDetailsData = (id: string) =>
  httpClient()
    .authorized.get<PinDetailsResponse>(`${PIN_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PinDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const getPinCategoriesDictionaryData = () =>
  httpClient()
    .authorized.get<PinCategoriesListResponse>(PIN_CATEGORIES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new PinCategoriesDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
