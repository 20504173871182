import { ActionType, Action, createAction } from 'typesafe-actions';

import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import type { PinCategoriesDictionaryVM, PinDetailsVM, PinsListVM, PinsParams } from '@/models/pins';

export const pinsActions = {
  list: createFetchActions('FETCH_PINS_LIST_REQUEST', 'FETCH_PINS_LIST_SUCCESS', 'FETCH_PINS_LIST_FAILURE')<PinsParams, PinsListVM>(),
  details: createFetchActions('FETCH_PIN_DETAILS_REQUEST', 'FETCH_PIN_DETAILS_SUCCESS', 'FETCH_PIN_DETAILS_FAILURE')<string, PinDetailsVM>(),
  clearPinDetails: createAction('CLEAR_PIN_DETAILS')(),
  pinCategories: createDictionaryActions(
    'FETCH_PIN_CATEGORIES_DICTIONARY_REQUEST',
    'FETCH_PIN_CATEGORIES_DICTIONARY_SUCCESS',
    'FETCH_PIN_CATEGORIES_DICTIONARY_FAILURE',
    'FETCH_PIN_CATEGORIES_DICTIONARY_CANCEL',
    'FETCH_PIN_CATEGORIES_DICTIONARY_SILENT_REQUEST'
  )<undefined, PinCategoriesDictionaryVM>(),
};

export type pinsActions = Action | ActionType<typeof pinsActions>;
