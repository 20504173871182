import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, type DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { type PinCategoriesDictionaryVM } from '@/models/pins';
import { type FeatureStateType } from '../../helpers';
import { pinsActions } from '../actions';

type pinCategoriesState = DictionaryState<PinCategoriesDictionaryVM>;

const INITIAL_STATE: pinCategoriesState = getDictionaryInitialState<PinCategoriesDictionaryVM>();

const reducer = createReducer<pinCategoriesState, pinsActions>(INITIAL_STATE)
  .handleAction(pinsActions.pinCategories.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(pinsActions.pinCategories.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(pinsActions.pinCategories.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(pinsActions.pinCategories.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(pinsActions.pinCategories.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const pinCategoriesDictionaryReducer = { pinCategories: reducer };
export type pinCategoriesDictionaryReducer = FeatureStateType<typeof pinCategoriesDictionaryReducer>;
