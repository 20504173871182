import React, { type FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { IconButton } from '@/common/components/icon-button';
import { interfaceActions, makeGetCurrentTheme } from '@/store/interface';
import { THEME } from '@/styles/models';
import { styles } from './styles';

const getCurrentTheme = makeGetCurrentTheme();

export const ThemeToggle: FC = () => {
  const dispatch = useDispatch();
  const { themeName } = useSelector(getCurrentTheme);

  const handleToggleTheme = useCallback(
    (theme: string) => () => {
      dispatch(interfaceActions.setTheme(theme));
      localStorage.setItem('currentTheme', theme);
    },
    [dispatch]
  );

  return (
    <div css={styles}>
      <IconButton iconName='mdi-light_mode' className={cn({ active: themeName === THEME.LIGHT })} onClick={handleToggleTheme(THEME.LIGHT)} />
      <IconButton iconName='mdi-dark_mode' className={cn({ active: themeName === THEME.DARK })} onClick={handleToggleTheme(THEME.DARK)} />
    </div>
  );
};
