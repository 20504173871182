import { switchMap, filter, map } from 'rxjs/operators';
import { type Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getPinCategoriesDictionaryData, getPinDetailsData, getPinList } from '@/features/content/services/pins/pins';
import { CommonError, dictionaryEffectHelper } from '@/common/utils';
import { type pinsReducer } from './reducers';
import { pinsActions } from './actions';

const getPinsList: Epic<pinsActions, pinsActions, pinsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(pinsActions.list.request)),
    switchMap(() =>
      getPinList(state$.value.pins.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return pinsActions.list.failure(response);
          }

          return pinsActions.list.success(response);
        })
      )
    )
  );

const getPinDetails: Epic<pinsActions, pinsActions, pinsReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(pinsActions.details.request)),
    switchMap(action =>
      getPinDetailsData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return pinsActions.details.failure(response);
          }

          return pinsActions.details.success(response);
        })
      )
    )
  );

const getPinCategoriesDictionary: Epic<pinsActions, pinsActions, pinsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(pinsActions.pinCategories.request)),
    switchMap(() => {
      const state = state$.value.pins.pinCategories;
      return dictionaryEffectHelper(() => getPinCategoriesDictionaryData(), state, pinsActions.pinCategories);
    })
  ) as any;

export const pinsEpics = [getPinsList, getPinDetails, getPinCategoriesDictionary];
