import uniqueId from 'lodash/uniqueId';

import { CustomFile, FileInputPresetProps, FileType } from './models';

export const MAP_MIME_TYPE = 'application/vnd';
export const GPX_MIME_TYPE = 'application/gpx+xml';
export const IMAGE_MIME_TYPE = 'image/jpeg';
export const OTHER_MIME_TYPE = 'application/octet-stream';

export const FileInputPreset = {
  [FileType.Map]: new FileInputPresetProps(FileType.Map, MAP_MIME_TYPE, ['.gpx', '.kml']).props,
  [FileType.Gpx]: new FileInputPresetProps(FileType.Map, GPX_MIME_TYPE, ['.gpx']).props,
  [FileType.Image]: new FileInputPresetProps(FileType.Image, IMAGE_MIME_TYPE, ['.jpg', '.jpeg', '.png']).props,
  [FileType.Other]: new FileInputPresetProps(FileType.Other, OTHER_MIME_TYPE).props,
};

export const FileInputPresetOverride = {
  [FileType.Map]: (accept: string[]) => new FileInputPresetProps(FileType.Map, MAP_MIME_TYPE, accept).props,
  [FileType.Gpx]: (accept: string[]) => new FileInputPresetProps(FileType.Gpx, GPX_MIME_TYPE, accept).props,
  [FileType.Image]: (accept: string[]) => new FileInputPresetProps(FileType.Image, IMAGE_MIME_TYPE, accept).props,
  [FileType.Other]: (accept: string[]) => new FileInputPresetProps(FileType.Other, OTHER_MIME_TYPE, accept).props,
};

export const isCustomFile = function (file: File | CustomFile): file is CustomFile {
  return !!(file as CustomFile).id;
};

export const DRAG_AREA_ID = uniqueId('DRAG_AREA');
export const UPLOAD_BUTTON_ID = uniqueId('UPLOAD_BUTTON');
